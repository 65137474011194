import React, { useState, useEffect, useMemo } from "react";
import endOfDay from "date-fns/endOfDay";
import startOfDay from "date-fns/startOfDay";
import Swal from "sweetalert2";
import HistoricTable from "./HistoricTable";
import Chart from "chart.js";
import Export from "./Export";
import { getColor, getInitialObject } from "utils/globalFunctions";
import { months, necropsyConfig } from "utils/globalData";
import { api } from "services/api";
import { SubHeader } from "components/SubHeader";
import { Icons } from "components/Icons";
import { Col, Row } from "styles";
import { Filters } from "./Filters";
import { BoxInjuries } from "./BoxInjuries";
import { GraphScore } from "./GraphScore";
import { GraphConsolidado } from "./GraphConsolidado";
import { Graph } from "./Graph";
import { BoxScores } from "./BoxScores";
import { CurrentHistoric } from "./CurrentHistoric";
import { GpdScore } from "./GpdScore/index.";
import { Necropsies } from "./Necropsies";
import { Button } from "components/Button";
import Enviar from "./Enviar";
import { WeightReport } from "./Weight";

export function Report(props) {
  Chart.pluginService.register({
    beforeRender: function (chart) {
      if (chart.config.options.showAllTooltips) {
        chart.pluginTooltips = [];
        chart.config.data.datasets.forEach(function (dataset, i) {
          if (dataset.label === "Score Connect (Digestivo)") {
            chart.getDatasetMeta(i).data.forEach(function (sector, j) {
              chart.pluginTooltips.push(
                new Chart.Tooltip(
                  {
                    _chart: chart.chart,
                    _chartInstance: chart,
                    _data: chart.data,
                    _options: chart.options.tooltips,
                    _active: [sector],
                  },
                  chart
                )
              );
            });
          }
        });

        chart.options.tooltips.enabled = false;
      }
    },
    afterDraw: function (chart, easing) {
      if (chart.config.options.showAllTooltips) {
        if (!chart.allTooltipsOnce) {
          if (easing !== 1) return;
          chart.allTooltipsOnce = true;
        }

        chart.options.tooltips.enabled = true;
        Chart.helpers.each(chart.pluginTooltips, function (tooltip) {
          tooltip.initialize();
          tooltip.update();
          tooltip.pivot();
          tooltip.transition(easing).draw();
        });
      }
    },
  });
  const initialFarmData = useMemo(
    () => ({
      _id: "",
      customer: "",
      health: {
        anticoccidian: {
          moleculePre: "",
          moleculeInitial: "",
          moleculeGrowth: "",
          moleculeGrowth2: "",
          moleculeFinal: "",
          dosePre: "",
          doseInitial: "",
          doseGrowth: "",
          doseGrowth2: "",
          doseFinal: "",
        },
        performanceEnhancer: {
          pre: {
            product: "",
            dose: "",
          },
          initial: {
            product: "",
            dose: "",
          },
          growth: {
            product: "",
            dose: "",
          },
          growth2: {
            product: "",
            dose: "",
          },
          final: {
            product: "",
            dose: "",
          },
        },
      },
      additions: {
        organicAcids: {
          pre: {
            product: "",
            dose: "",
          },
          initial: {
            product: "",
            dose: "",
          },
          growth: {
            product: "",
            dose: "",
          },
          growth2: {
            product: "",
            dose: "",
          },
          final: {
            product: "",
            dose: "",
          },
        },
        mycotoxinAdsorbents: {
          pre: {
            product: "",
            dose: "",
          },
          initial: {
            product: "",
            dose: "",
          },
          growth: {
            product: "",
            dose: "",
          },
          growth2: {
            product: "",
            dose: "",
          },
          final: {
            product: "",
            dose: "",
          },
        },
        essencialOils: {
          pre: {
            product: "",
            dose: "",
          },
          initial: {
            product: "",
            dose: "",
          },
          growth: {
            product: "",
            dose: "",
          },
          growth2: {
            product: "",
            dose: "",
          },
          final: {
            product: "",
            dose: "",
          },
        },
        prebiotics: {
          pre: {
            product: "",
            dose: "",
          },
          initial: {
            product: "",
            dose: "",
          },
          growth: {
            product: "",
            dose: "",
          },
          growth2: {
            product: "",
            dose: "",
          },
          final: {
            product: "",
            dose: "",
          },
        },
        probiotics: {
          pre: {
            product: "",
            dose: "",
          },
          initial: {
            product: "",
            dose: "",
          },
          growth: {
            product: "",
            dose: "",
          },
          growth2: {
            product: "",
            dose: "",
          },
          final: {
            product: "",
            dose: "",
          },
        },
      },
    }),
    []
  );
  const initialResponse = useMemo(
    () => ({
      perPeriod: [],
      totalBirds: 0,
      totalPerInjury: {},
      totalPerScore: {},
      customer: props.match.params.id,
      createdBy: null,
      injuryType: [],
      group: "Semanal",
      broilerList: [],
      necropsies: [],
      weights: {},
    }),
    [props.match.params.id]
  );

  const broiler = [
    { label: "Fitase Hidolsing", value: "enzymes.phytaseHidosing" },
    { label: "Corboidrase/Blends", value: "enzymes.carbohydraseBlends" },
    { label: "EnzyPac Pro / Protease", value: "enzymes.enziPacProProtease" },
    { label: "Anticoccidiano", value: "health.anticoccidian" },
    { label: "Medicação Terapeutica", value: "health.therapeuticMedication" },
    { label: "Melhorador de Desempenho", value: "health.performanceEnhancer" },
    { label: "Minerais Orgânicos", value: "minerals.organics" },
    { label: "Ácidos Orgânicos", value: "additions.organicAcids" },
    {
      label: "Absorventes de Micotoxina",
      value: "additions.mycotoxinAdsorbents",
    },
    { label: "Óleos Essenciais", value: "additions.essencialOils" },
    { label: "Prebióticos", value: "additions.prebiotics" },
    { label: "Probióticos", value: "additions.probiotics" },
  ];

  const typesOfInjuries = [
    { value: "digestive", label: "Digestório" },
    { value: "locomotor", label: "Locomotor" },
    { value: "respiratory", label: "Respiratório" },
    { value: "immunological", label: "Imunológico" },
    { value: "integumentary", label: "Tegumentar" },
  ];

  const selections = useMemo(
    () => [
      { value: "score", label: "Score" },
      { value: "ca", label: "CA" },
      { value: "birds", label: "No aves" },
      { value: "age", label: "Idade" },
      { value: "averageWeight", label: "PM" },
      { value: "mortality", label: "Mortalidade" },
      { value: "density", label: "Densidade (aves/m2)" },
      { value: "sanitaryVoid", label: "Vazio Sanitário (d)" },
      { value: "gpd", label: "GPD" },
      { value: "iep", label: "IEP" },
      { value: "chickWeight", label: "Peso de pinto" },
      { value: "cac", label: "C.A.C." },
      { value: "cost", label: "R$/Aves" },
    ],
    []
  );

  const [dateRange, setDateRange] = useState([
    {
      startDate: startOfDay(new Date()),
      endDate: endOfDay(new Date()),
      key: "selection",
    },
  ]);

  const [input, setInput] = useState({
    group: "Semanal",
    createdBy: null,
    customer: props.match.params.id,
    age: [0, 60],
    injuryType: [],
    lineage: "",
    technicalManager: null,
    technicalManagerList: [],
    comparison: false,
    broilerList: [],
    nutritionList: [],
    gpd: {},
    weightReport: {},
  });

  const [responseReport, setResponseReport] = useState(initialResponse);

  const [generateReport, setGenerateReport] = useState(true);

  const [loading, setLoading] = useState(false);

  const [isReloadingPage, setIsReloadingPage] = useState(true);

  const [modalShow, setModalShow] = useState(false);

  const [modalShowEnviar, setModalShowEnviar] = useState(false);

  const [farmData, setFarmData] = useState(initialFarmData);

  const [customer, setCustomer] = useState({});

  const [reportConsolidated, setReportConsolidated] = useState({});

  const [responseConsolidated, setResponseConsolidated] = useState({
    graph: [],
  });

  const [filter, setFilter] = useState("all");

  const [selection, setSelection] = useState({ x: "ca", y: "gpd" });

  const [runConsolidated, setRunConsolidated] = useState(false);

  const [nutritionList, setNutritionList] = useState([]);

  const reportHandler = () => {
    if (input.injuryType.length === 0) {
      return Swal.fire(
        "Relatório",
        "Selecione pelo menos um tipo de lesão",
        "error"
      );
    }
    const query = { ...input, dateRange };
    setLoading(true);
    props.history.replace(
      `/customer/connect/broiler/report/${
        props.match.params.id
      }/${JSON.stringify(query)}`
    );
    setGenerateReport(true);
  };

  const getPerPeriod = () => {
    const response = [];
    responseReport.perPeriod.forEach((array) => {
      array.forEach((item) => {
        if (
          !response.find(
            ({ endOfWeek, startOfWeek }) =>
              item.endOfWeek === endOfWeek && item.startOfWeek === startOfWeek
          ) ||
          !response.find(
            ({ startOfMonth, endOfMonth }) =>
              item.endOfMonth === endOfMonth &&
              item.startOfMonth === startOfMonth
          )
        ) {
          response.push(item);
        }
      });
    });

    return response;
  };

  const showReport = (isConsolidated) =>
    isConsolidated
      ? responseConsolidated &&
        responseConsolidated.graph[0] &&
        !loading &&
        !isReloadingPage &&
        props.match.params.query !== "{}"
      : !isReloadingPage && !loading && props.match.params.query !== "{}";

  const saveReport = async () => {
    const responseSave = await Swal.fire({
      title: "Salvar Relatório",
      text: "Deseja salvar o relatório no banco de dados?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0451e8",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, salvar!",
      cancelButtonText: "Não, cancelar!",
      showLoaderOnConfirm: true,
      preConfirm: async () =>
        await api[props.id ? "put" : "post"](
          `/connect/reportbroiler${props.id ? `/${props.id}` : ""}`,
          {
            url: props.location.pathname,
            customer: props.match.params.id,
          }
        ).catch((err) => ({
          err: true,
          data: { message: err.response.data.message },
        })),
    });
    if (responseSave.err) {
      Swal.fire("Salvar Relatório", "Erro ao salvar relatório!", "error");
    } else {
      const newUrl = `${props.location.pathname}/${responseSave.value.data.id}`;
      props.history.replace(newUrl);
    }
  };

  useEffect(() => {
    const loadReport = async () => {
      try {
        const params = JSON.parse(props.match.params.query);
        const responseNecropsy = await api
          .post("/connect/broiler/report", {
            ...props.match.params,
            keys: params.injuryType.map((injuryType) =>
              Object.keys(necropsyConfig[injuryType]).filter(
                (key) => necropsyConfig[injuryType][key].report
              )
            ),
          })
          .catch((e) => {
            return e;
          });
        if (!responseNecropsy.data) {
          setLoading(false);
          return Swal.fire("Relatório", responseNecropsy.message, "error");
        }

        setResponseReport(JSON.parse(JSON.stringify(responseNecropsy.data)));
        setInput({
          group: params.group,
          createdBy: params.createdBy,
          injuryType: params.injuryType,
          customer: props.match.params.id,
          age: params.age,
          technicalManager: params.technicalManager,
          technicalManagerList: params.technicalManagerList || [],
          comparison: params.comparison || false,
          lineage: params.lineage,
          broilerList: params.broilerList,
          nutritionList: params.nutritionList,
        });
        setDateRange([
          {
            startDate: startOfDay(new Date(params.dateRange[0].startDate)),
            endDate: endOfDay(new Date(params.dateRange[0].endDate)),
            key: "selection",
          },
        ]);
        const responseFarmData = await api.get(
          `broilernecropsy/historic/${params.customer}`
        );
        setFarmData(getInitialObject(initialFarmData, responseFarmData.data));
        const responseConsolidated = await api
          .post("broilerconsolidated/report", props.match.params)
          .catch((e) => {
            return e;
          });

        responseNecropsy.data.perPeriod.forEach(
          ({ totalScore, week, month, year }) => {
            const index = responseConsolidated.data.graph
              .map(({ key }) => key)
              .indexOf(
                `all;Consolidado;${month};${year}${
                  params.group === "Semanal" ? ";" + week : ""
                }`
              );
            if (index > -1) {
              responseConsolidated.data.graph[index].score = +totalScore;
            }
          }
        );

        setResponseConsolidated(responseConsolidated.data);
        if (
          responseNecropsy.data.totalBirds === 0 &&
          (!responseConsolidated.data || !responseConsolidated.data.graph[0])
        ) {
          setLoading(false);
          return Swal.fire(
            "Relatório",
            "Nenhum da dado localizado!",
            "warning"
          );
        }
        setLoading(false);
        setIsReloadingPage(false);
      } catch (e) {
        console.log(e);
      }
    };
    const reportConsolidatedHandler = (data) => {
      if (!data) return;
      const labels = [
        ...new Set(
          data.graph
            .filter((item) => item.key.split(";")[0] === filter)
            .map((item) => {
              const keys = item.key.split(";");
              return `${keys[2]};${keys[3]}` + (keys[4] ? `;${keys[4]}` : "");
            })
        ),
      ];
      const datasets = [];
      data.graph
        .filter((item) => item.key.split(";")[0] === filter)
        .forEach((item) => {
          let label = `${item.key.split(";")[1]}-${
            selections[
              selections.map(({ value }) => value).indexOf(selection.x)
            ].label
          }`;
          const index = datasets.map(({ label }) => label).indexOf(label);
          if (index < 0) {
            let color = getColor();
            datasets.push({
              label,
              data: labels.map((date) => {
                const index = data.graph
                  .map(({ key }) => key)
                  .indexOf(`${filter};${label.split("-")[0]};${date}`);
                return index > -1
                  ? (
                      data.graph[index][selection.x] /
                      (selection.x === "score" ? 1 : data.graph[index].count)
                    ).toFixed(3)
                  : null;
              }),
              backgroundColor: getColor(color, 0.4),
              borderColor: color,
              borderDash: [],
              fill: false,
              lineTension: 0.1,
              borderDashOffset: 0.0,
              pointBackgroundColor: "#fff",
              pointBorderWidth: 1,
              pointHoverRadius: 5,
              pointHoverBorderWidth: 2,
              pointRadius: 1,
              pointHitRadius: 10,
              yAxisID: "y-axis-1",
            });
            color = getColor();
            label = `${item.key.split(";")[1]}-${
              selections[
                selections.map(({ value }) => value).indexOf(selection.y)
              ].label
            }`;
            datasets.push({
              label,
              data: labels.map((date) => {
                const index = data.graph
                  .map(({ key }) => key)
                  .indexOf(`${filter};${label.split("-")[0]};${date}`);
                return index > -1
                  ? (
                      data.graph[index][selection.y] /
                      (selection.y === "score" ? 1 : data.graph[index].count)
                    ).toFixed(3)
                  : null;
              }),
              backgroundColor: getColor(color, 0.4),
              borderColor: color,
              borderDash: [],
              fill: false,
              lineTension: 0.1,
              borderDashOffset: 0.0,
              pointBackgroundColor: "#fff",
              pointBorderWidth: 1,
              pointHoverRadius: 5,
              pointHoverBorderWidth: 2,
              pointRadius: 1,
              pointHitRadius: 10,
              yAxisID: "y-axis-2",
            });
          }
        });

      setReportConsolidated({
        labels: labels.map(
          (item) =>
            (item.split(";")[2] ? `Semana ${item.split(";")[2]} - ` : "") +
            `${months[+item.split(";")[0]]} ${item.split(";")[1]}`
        ),
        datasets,
      });
    };

    if (generateReport && props.match.params.query !== "{}") {
      setGenerateReport(false);
      setLoading(true);
      loadReport();
    }
    if (runConsolidated) {
      setRunConsolidated(false);
      reportConsolidatedHandler(responseConsolidated);
    }
  }, [
    generateReport,
    props.match.params,
    initialFarmData,
    initialResponse,
    filter,
    selection,
    responseConsolidated,
    runConsolidated,
    selections,
    customer,
  ]);

  useEffect(() => {
    const getCustomerName = async () => {
      const responseCustomer = await api.get(
        `connect/customer/${props.match.params.id}`
      );
      setCustomer(responseCustomer.data);
      setLoading(false);
    };
    if (!customer._id) {
      getCustomerName();
    }
  }, [props.match.params.id, customer._id]);

  useEffect(() => {
    if (input.age[1] > 60) {
      setInput((prevState) => {
        const newState = JSON.parse(JSON.stringify(prevState));
        newState.age[1] = 60;
        return newState;
      });
    }
  }, [input.age]);

  useEffect(() => {
    if (props.match.params.query === "{}") {
      setDateRange([
        {
          startDate: startOfDay(new Date()),
          endDate: endOfDay(new Date()),
          key: "selection",
        },
      ]);
      setInput({
        group: "Semanal",
        createdBy: null,
        injuryType: [],
        customer: props.match.params.id,
        age: [0, 60],
        lineage: "",
        technicalManager: "",
        technicalManagerList: [],
        comparison: false,
        broilerList: [],
        nutritionList: [],
      });

      setResponseReport({
        perPeriod: [],
        totalBirds: 0,
        totalPerInjury: {},
        totalPerScore: {},
        customer: props.match.params.id,
        createdBy: null,
        injuryType: [],
        group: "Semanal",
        age: [0, 60],
        lineage: "",
        broilerList: [],
        weights: {},
      });
    }
  }, [props.match.params]);

  useEffect(() => {
    if (input.technicalManager) {
      setInput((prevState) => {
        const newState = JSON.parse(JSON.stringify(prevState));
        newState.technicalManagerList.push({
          _id: prevState.technicalManager._id,
          name: prevState.technicalManager.name,
        });
        newState.technicalManager = null;
        return newState;
      });
    }
  }, [input.technicalManager]);

  useEffect(() => {
    const getNutritionList = async () => {
      const responseNutritionList = await api.get("necropsyconfiglists");
      setNutritionList(
        responseNutritionList.data.filter(({ key }) => key === "nutrition")
      );
    };
    getNutritionList();
  }, []);

  return (
    <>
      {modalShow ? (
        <Export
          show={modalShow}
          onHide={() => setModalShow(false)}
          location={props.location}
          responseReport={responseReport}
          customer={props.match.params.id}
          id={props.match.params.reportId}
          broilerList={JSON.parse(props.match.params.query).broilerList}
          history={props.history}
          injuryType={JSON.parse(props.match.params.query).injuryType}
        />
      ) : null}
      {modalShowEnviar ? (
        <Enviar
          show={modalShowEnviar}
          onHide={() => setModalShowEnviar(false)}
          location={props.location}
          responseReport={responseReport}
          customer={props.match.params.id}
          id={props.match.params.reportId}
          broilerList={JSON.parse(props.match.params.query).broilerList}
        />
      ) : null}
      <SubHeader
        {...props}
        title={customer.name}
        breadcrumbs={[
          {
            label: customer.name,
            to: "/customer/" + props.match.params.id,
          },
          {
            label: "Frango de Corte",
          },
          {
            label: "Relatório",
          },
        ]}
        icon={<Icons type="lineChart" size="24" color="#fff" />}
        otherButtons={
          responseReport.totalBirds > 0
            ? [
                <Button
                  type="button"
                  bg="primary"
                  color="white"
                  border="primary"
                  onClick={() => setModalShowEnviar(true)}
                  disabled={!props.match.params.reportId}
                >
                  Enviar Relatório &nbsp; <i className="fas fa-mail-bulk" />
                </Button>,
                <Button
                  type="button"
                  bg="white"
                  color="black"
                  border="white"
                  onClick={() => setModalShow(true)}
                >
                  Exportar Relatório &nbsp; <i className="far fa-file-excel" />
                </Button>,
                <Button
                  type="button"
                  bg="primary"
                  color="white"
                  border="primary"
                  onClick={saveReport}
                >
                  Salvar Relatório &nbsp; <i className="fas fa-save" />
                </Button>,
              ]
            : null
        }
      />
      <Row>
        <Col size={1}>
          <Filters
            {...{
              input,
              setInput,
              loading,
              id: props.match.params.id,
              typesOfInjuries,
              reportHandler,
              dateRange,
              setDateRange,
              broiler,
              nutritionList,
            }}
          />
        </Col>

        <Col size={3}>
          {showReport() ? (
            <BoxInjuries
              {...{
                responseReport,
                query: props.match.params.query,
                typesOfInjuries,
                dateRange,
              }}
            />
          ) : null}
        </Col>
      </Row>
      {showReport() ? (
        <GraphScore
          {...{
            responseReport,
            dateRange,
            input,
            typesOfInjuries,
            query: props.match.params.query,
          }}
        />
      ) : null}
      {showReport(true) ? (
        <GraphConsolidado
          {...{
            dateRange,
            responseReport,
            filter,
            setFilter,
            setRunConsolidated,
            selection,
            setSelection,
            selections,
            reportConsolidated,
          }}
        />
      ) : null}
      {showReport() ? (
        <>
          <Graph
            {...{
              responseReport,
              dateRange,
              input,
              typesOfInjuries,
              query: props.match.params.query,
            }}
          />
          <WeightReport
            gpd={responseReport.gpd}
            averageWeights={responseReport.averageWeights}
            weights={responseReport.weights}
          />
          <GpdScore {...{ dateRange, responseReport }} />
          {JSON.parse(props.match.params.query).broilerList[0] ? (
            <HistoricTable
              perPeriod={getPerPeriod()}
              type={responseReport?.group}
              broiler={broiler}
              broilerList={JSON.parse(props.match.params.query).broilerList}
            />
          ) : null}
          {JSON.parse(props.match.params.query).injuryType.indexOf(
            "digestive"
          ) > -1 && (
            <BoxScores
              {...{
                query: props.match.params.query,
                responseReport,
                dateRange,
              }}
            />
          )}

          <CurrentHistoric {...{ responseReport, farmData }} />

          <Necropsies
            {...{
              responseReport,
              location: props.location,
              customer,
              match: props.match,
            }}
          />
        </>
      ) : null}
    </>
  );
}
