import { Input } from "components/Input";
import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Block,
  BlockBody,
  BlockBodyContent,
  BlockHeader,
  Col,
  Row,
} from "styles";

export const WeightReport = ({ gpd, averageWeights, weights }) => {
  const [data, setData] = useState({});
  const [hideAll, setHideAll] = useState(false);

  useEffect(() => {
    setData((prevState) => ({
      ...prevState,
      datasets: prevState.datasets.map((dataset) => ({
        ...dataset,
        hidden: hideAll,
      })),
    }));
  }, [hideAll]);

  useEffect(() => {
    const weeks = [0, 1, 2, 3, 4, 5, 6];

    const getDots = (lineage) => {
      const data = [];
      Object.entries(weights[lineage]).forEach(([week, value], index) => {
        value[0].forEach((weight) => {
          data.push({
            x: `Semana ${index}`,
            y: weight,
            r: 5,
          });
        });
      });
      return data;
    };

    const data = {
      labels: weeks.map((week) => `Semana ${week}`),
      datasets: [
        {
          label: "Cobb Média Real",
          data: Object.values(averageWeights.Cobb)
            .slice(0, 7)
            .map((value) => +value || null),
          borderColor: "rgba(75, 192, 192, 1)",
          backgroundColor: "rgba(75, 192, 192, 0.02)",
          tension: 0.4,
        },
        {
          label: "Cobb Manual",
          data: gpd.Cobb.filter(({ week }) => week < 7).map(
            (data) => data.weight
          ),
          borderColor: "rgba(255, 99, 132, 1)",
          backgroundColor: "rgba(255, 99, 132, 0.02)",
          tension: 0.4,
        },
        {
          label: "Ross Média Real",
          data: Object.values(averageWeights.Ross)
            .slice(0, 7)
            .map((value) => +value || null),
          borderColor: "rgba(54, 162, 235, 1)",
          backgroundColor: "rgba(54, 162, 235, 0.02)",
          tension: 0.4,
        },
        {
          label: "Ross Manual",
          data: gpd.Ross.filter(({ week }) => week < 7).map(
            (data) => data.weight
          ),
          borderColor: "rgba(255, 159, 64, 1)",
          backgroundColor: "rgba(255, 159, 64, 0.02)",
          tension: 0.4,
        },
        {
          label: "Hubbard Média Real",
          data: Object.values(averageWeights.Hubbard)
            .slice(0, 7)
            .map((value) => +value || null),
          borderColor: "rgba(153, 102, 255, 1)",
          backgroundColor: "rgba(153, 102, 255, 0.02)",
          tension: 0.4,
        },
        {
          label: "Hubbard Manual",
          data: gpd.Rubbard.filter(({ week }) => week < 7).map(
            (data) => data.weight
          ),
          borderColor: "rgba(255, 206, 86, 1)",
          backgroundColor: "rgba(255, 206, 86, 0.02)",
          tension: 0.4,
        },
        {
          type: "bubble",
          label: "Cobb Pesos Reais",
          data: getDots("Cobb"),
          borderColor: "rgb(62, 157, 157)",
          backgroundColor: "rgba(75, 192, 192, 1)",
        },
        {
          type: "bubble",
          label: "Ross Pesos Reais",
          data: getDots("Ross"),
          borderColor: "rgb(38, 108, 155)",
          backgroundColor: "rgba(54, 162, 235, 1)",
        },
        {
          type: "bubble",
          label: "Hubbard Pesos Reais",
          data: getDots("Hubbard"),
          borderColor: "rgb(103, 69, 170)",
          backgroundColor: "rgba(153, 102, 255, 1)",
        },
      ],
    };

    setData(data);
  }, [gpd, averageWeights, weights]);

  return (
    <Block id={`weight`}>
      <BlockHeader bg="https://zenbytes-public.sfo2.digitaloceanspaces.com/mcassab/menu-top-connect.png">
        Peso Manual x Real
      </BlockHeader>
      <BlockBody>
        <BlockBodyContent>
          <Input
            type="checkbox"
            item={hideAll}
            setItem={setHideAll}
            label="Esconder Todos"
            onChange={() => {
              setHideAll(!hideAll);
            }}
            style={{ marginBottom: "20px" }}
          />

          <Line
            data={data}
            options={{
              scales: {
                y: {
                  beginAtZero: true,
                  max: 4,
                },

                x: {
                  type: "category",
                  labels: [
                    "Peso Pintinho",
                    "Semana 1",
                    "Semana 2",
                    "Semana 3",
                    "Semana 4",
                    "Semana 5",
                    "Semana 6",
                  ],
                },
              },
            }}
          />
        </BlockBodyContent>
      </BlockBody>
    </Block>
  );
};
